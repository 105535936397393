<template>
  <div
    id="page-content"
    class="min-w-screen relative flex min-h-screen w-full grow flex-col"
  >
    <div
      id="absolute-target"
      class="relative z-50"
    />
    <NotificationsView />
    <ConfirmationDialogs />
    <RouterView />
    <CookiesBanner class="fixed bottom-4 right-8 z-10" />
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { use } from '@/components/Booking/useNearestBookings'
import CookiesBanner from '@/components/CookiesBanner'
import NotificationsView from '@/components/NotificationsView'
import { init } from '@/composables/useProject'
import { connect } from '@/composables/useServerEvents'

const ConfirmationDialogs = defineAsyncComponent(() =>
  import('@/components/Layout/ConfirmationDialogs')
)
init().then(connect)
use()
</script>
